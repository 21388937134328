// src/pages/PrivacyPolicy.js

import React from 'react';
import privacyIcon from '../assets/privacy-icon.png'; // Import privacy icon


function PrivacyPolicy() {
  return (
    <div style={{padding: 30}}>
      <h1>Privacy Policy</h1>
      <h2 style={{marginTop: 10}}>Last Updated: April 4, 2024</h2>
      <h3 style={{marginTop: 10}}>Introduction:</h3>
      <p>At Goomi, we respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application ("App") and related services ("Services") in South Africa.</p>
   
      <h3 style={{marginTop: 10}}>Information We Collect:</h3>
   
      <p>We collect a variety of information from you when you use our App and Services, including:</p>
      <ul>
        
      <li>Personal Information: Name, email address, phone number, delivery address, age (for alcohol purchases), etc.</li>
      <li>Location Information: Geolocation data to determine your delivery area and improve service efficiency.</li>
      <li>Order Information: Items purchased, order details, payment information, etc.</li>
      <li>Device Information: Device type, operating system, IP address, usage data, etc.</li>
      </ul>


      <h3 style={{marginTop: 10}}>How We Use Your Information:</h3>
      <p>We use your information to:</p>
      <ul>
        
      <li>Provide and personalize your Goomi experience.</li>
      <li>Process orders and deliver products accurately.</li>
      <li>Communicate with you about orders, promotions, and support.</li>
      <li>Improve our App and Services and develop new features.</li>
      <li>Comply with legal and regulatory requirements.</li>
      </ul>


      <h3 style={{marginTop: 10}}>Disclosure of Your Information:</h3>
      <p>We use your information to:</p>

      <ul>
        <li>Service providers who help us operate and improve our App and Services.</li>
        <li>Payment processors to facilitate transactions.</li>
        <li>Regulatory authorities as required by law.</li>
        <li>Third parties with your consent, such as loyalty programs or promotional partners.</li>
      </ul>

      <h3 style={{marginTop: 10}}>Data Security:</h3>
      <p>We implement appropriate technical and organizational measures to protect your information from unauthorized access, use, disclosure, alteration, or destruction. However, no website or internet transmission is entirely secure.</p>
      
      <h3 style={{marginTop: 10}}>Your Privacy Choices:</h3>
      <p>You have choices regarding your information:</p>

      <ul>
        <li>Access and update your personal information.</li>
        <li>Request deletion of your data.</li>
        <li>Opt out of marketing communications.</li>
      </ul>

      <p>Please contact us through the App or at goomi@sundaycreatives.co.za to exercise your choices.</p>


      <h3 style={{marginTop: 10}}>Data Retention:</h3>
      <p>We retain your information for as long as necessary to provide the Services, comply with legal obligations, and resolve disputes.</p>

      <h3 style={{marginTop: 10}}>Children's Privacy:</h3>
      <p>We do not knowingly collect information from children under 18. If you are a parent or guardian and believe your child has provided us with personal information, please contact us.</p>

      <h3 style={{marginTop: 10}}>Cross-Border Data Transfers:</h3>
      <p>Your information may be transferred to servers outside of South Africa, where data protection laws may differ. We will take appropriate safeguards to protect your information in these cases.</p>

      <h3 style={{marginTop: 10}}>Changes to this Policy:</h3>
      <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the updated version on the App.</p>

      <h3 style={{marginTop: 10}}>Contact Us:</h3>
      <p>If you have any questions about this Privacy Policy, please contact us through the App or at goomi@sundaycreatives.co.za</p>


    </div>
  );
}

export default PrivacyPolicy;
